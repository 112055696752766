<template>
  <section>
    <v-overlay :value="displayLoadingAnimation">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>
    <v-row justify="center">
      <v-dialog v-model="displayInvalidInviteDialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent>
        <v-card>
          <v-app-bar dark color="accent" height="70px">
            <div>
              <v-icon color="yellow"> mdi-alert</v-icon>
            </div>
            <span class="ml-4 mr-0 my-4">&nbsp;</span>
            <v-toolbar-title>Can't redeem invitation</v-toolbar-title>
            <v-spacer></v-spacer>
            <div>
              <v-btn class="black--text font-weight-bold" color="grey lighten-5" elevation="10" height="30" :ripple="true" min-width="96" @click="homeAction()"> Home </v-btn>
            </div>
          </v-app-bar>
          <v-card-title size="text-h1">{{ retrieveInvitationFailureMessage }} </v-card-title>
          <v-card-text>Please request a new invitation or visit our homepage...</v-card-text>
        </v-card>
      </v-dialog>
    </v-row>

    <v-img :min-height="minBackgroundHeightProp" :src="require('@/assets/world-1999x921.jpeg')" class="white--text" gradient="to top, rgba(0,4,13, 0.6), rgba(0,0,0,.9)">
      <v-container>
        <v-responsive class="d-flex align-center mx-auto" height="100%" max-width="700" width="100%">
          <br /><br />
          <base-heading size="text-h4" :title="name" weight="medium" class="ml-4" />
          <v-row class="d-flex align-center justify-center mt-15 mb-16">
            <v-col cols="12" v-if="portraitModeProp" class="text-center mx-auto">
              <v-avatar tile size="144" class="mb-4">
                <v-img class="profile-image-style" :src="avatar"></v-img>
              </v-avatar>
            </v-col>
            <v-col v-else class="align-center justify-center mx-auto mb-4">
              <v-avatar tile size="144" class="pl-2 ml-4 mb-4">
                <v-img class="profile-image-style" :src="avatar"></v-img>
              </v-avatar>
            </v-col>
            <v-col cols="12" class="justify-center mx-auto mb-4">
              <div v-if="portraitModeProp" class="text-h6 mt-2 pa-2 ml-4 font-weight-thin">“Join our exclusive network of like-kind professionals and claim your territory..”</div>
              <div v-else class="text-h5 mt-2 pa-2 ml-4 font-weight-light">“Join our exclusive network of like-kind professionals and claim your territory.”</div>
            </v-col>
            <v-col cols="12" sm="12" class="text-start">
              <iframe src="https://player.vimeo.com/video/876202893?h=01d736e4d8&autoplay=1&title=0&byline=0&portrait=0" :width="videoWidthProp" :height="videoHeightProp" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
            </v-col>
          </v-row>
          <!-- <base-btn :ripple="true" height="auto" text color="white" @click="copyRedeemIdToClipboard()">Copy your Invitation Code:<v-icon v-show="!displayCodeCopiedIcon" right color="primary">mdi-content-copy</v-icon><v-icon v-show="displayCodeCopiedIcon" right color="success">mdi-check-bold</v-icon></base-btn> -->
          <div :class="$vuetify.breakpoint.mdAndDown ? 'flex-column align-start' : 'align-center'" class="d-flex flex-wrap">
            <!-- <div class="text-body-2 mt-2 pa-2 ml-4 font-weight-black accent">{{ redeemid }}</div> -->
            <!-- <span class="font-weight-medium ml-6 mr-4 my-4">&nbsp;</span> <v-spacer /> <v-spacer /> -->
            <base-heading weight="light" class="text-h5 pl-4">To get started, select your location and profession.</base-heading>

            <base-btn
              class="ml-4 mr-4 my-4"
              :ripple="false"
              color="info"
              @click="
                $vuetify.goTo('#get-started-section')
                copyRedeemIdToClipboard()
              "
              >Get Started<v-icon right>mdi-arrow-down</v-icon>
            </base-btn>
          </div>
        </v-responsive>
      </v-container>
    </v-img>
    <v-row justify="center" align="center" class="footer">
      <v-col cols="12" sm="12">
        <v-snackbar v-model="displayRetrieveInvitationFailureToast" :timeout="mediumTimeoutProp" color="error">
          <v-container fluid ma-0 pa-0 ml-4 fill-width style="width: 100%">
            <span class="centered">
              <v-btn rounded text> <v-icon>mdi-alert</v-icon> </v-btn> Something isn't right. <v-spacer />
              <v-btn @click="displayRetrieveInvitationFailureToast = false" text style="display: flex; justify-content: flex-end">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </span>
            ({{ retrieveInvitationFailureMessage }})
          </v-container>
        </v-snackbar>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { AuthServiceConstants } from '@/msal/AuthServiceInterface'
import { endpoints } from '@/environments/Endpoints.ts'
import { environmentVariables } from '@/environments/EnvironmentVariables.ts'
import { RegistrationStage, AlertTimeout } from '@/RegistrationStage.ts'
import { RemoteAccess } from '@/RemoteAccess'

export default {
  name: 'SectionInvited',
  provide: {
    theme: {
      isDark: true
    }
  },

  data() {
    return {
      displayInvalidInviteDialog: false,
      displayLoadingAnimation: true,
      displayRetrieveInvitationFailureToast: false,
      displayCodeCopiedIcon: false,
      name: '',
      retrieveInvitationFailureMessage: '',
      redeemid: '',
      avatar: ''
    }
  },

  computed: {
    mediumTimeoutProp() {
      return AlertTimeout.medium
    },

    minBackgroundHeightProp() {
      const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'
      return `calc(${height} - ${this.$vuetify.application.top}px)`
    },

    nullTimeoutProp() {
      return AlertTimeout.null
    },

    portraitModeProp() {
      return this.$vuetify.breakpoint.xsOnly
    },

    videoHeightProp() {
      return this.videoWidthProp * 0.56 // 16:9 ratio
    },

    videoWidthProp() {
      // at most 680 wide, minus a videoBorder of 5
      return Math.min(this.$vuetify.breakpoint.width, 680) - 5
    }
  },

  mounted() {
    setTimeout(this.fetchRegistrationStageAndInvite, this.nullTimeoutProp)
  },

  methods: {
    copyRedeemIdToClipboard() {
      this.displayCodeCopiedIcon = false
      navigator.clipboard.writeText(this.redeemid).then(() => {
        this.displayCodeCopiedIcon = true
      })
    },

    dismissdisplayRetrieveInvitationFailureToast() {
      this.displayRetrieveInvitationFailureToast = false
    },

    logoutAction() {
      this.$router.push({
        name: 'logout'
      })
    },

    homeAction() {
      this.$router.push({
        name: 'homeroute'
      })
    },

    profileAction() {
      this.$router.push({
        name: 'profileroute'
      })
    },

    fetchRegistrationStageAndInvite() {
      const remoteAccess = new RemoteAccess(this.$AuthService)
      remoteAccess.tokenAuthFunctionApp(
        '🥳 invitationPreviewSection.fetchRegistrationStageAndInvite', // log hint
        endpoints.myprofileEndpoint, // endpoint
        environmentVariables.MY_PROFILE_KEY, // code
        {}, // post body

        // hasPrerequisites ; must return 'true' to proceed
        () => true,

        // onSuccess
        (data) => this.updateProfileWithData(data.profile),

        // All sort of errors
        (error, _unusedDescription) => {
          if (AuthServiceConstants.LOGGED_OUT === error.message) {
            this.fetchInvitationDescription()
          } else {
            // we are logged-in, so even though there is an error fetching membership, redirect
            this.profileAction()
          }
        }
      )
    },

    fetchInvitationDescription() {
      const queryString = window.location.search
      if (!queryString || queryString === '') {
        // landed here without a redeemid, bail
        this.homeAction()
        return
      }

      const urlParams = new URLSearchParams(queryString)
      const redeemid = urlParams.get('invitation')

      RemoteAccess.publicFunctionApp(
        '📕 preview.fetchInvitationDescription', // log hint
        endpoints.invitationdescriptionEndpoint, // endpoint
        environmentVariables.INVITATION_DESCRIPTION_KEY, // code
        { redeemid: redeemid }, // post body

        // hasPrerequisites ; must return 'true' to proceed
        () => {
          this.displayCodeCopiedIcon = false
          this.displayInvalidInviteDialog = false
          this.displayLoadingAnimation = true
          this.displayRetrieveInvitationFailureToast = false

          return true
        },

        // onSuccess
        (data) => {
          this.avatar = data.invitedbyavataruri
          this.displayLoadingAnimation = false
          this.name = data.fullname
          this.redeemid = data.redeemid
        },

        // All sort of errors
        (_error, description) => {
          this.displayLoadingAnimation = false
          this.retrieveInvitationFailureMessage = description
          this.displayRetrieveInvitationFailureToast = true
        }
      )
    },

    updateProfileWithData(profile) {
      if (RegistrationStage.Empty === profile.registrationstage) {
        // Redeem code hasn't been used yet
        const urlParams = new URLSearchParams(window.location.search)
        const redeemid = urlParams.get('invitation')

        const remoteAccess = new RemoteAccess(this.$AuthService)
        remoteAccess.tokenAuthFunctionApp(
          '📄 invitationPreviewSection.updateProfileWithData', // log hint
          endpoints.updateprofileEndpoint, // endpoint
          environmentVariables.UPDATE_PROFILE_KEY, // code
          { redeemid: redeemid }, // post body

          // hasPrerequisites ; must return 'true' to proceed
          () => true,

          // onSuccess
          (_) => this.profileAction(),

          // All sort of errors
          (error, _unusedDescription) => {
            if (AuthServiceConstants.LOGGED_OUT === error.message) {
              this.showLoggedOutBanner()
            } else {
              this.profileAction()
            }
          }
        )
      }
    },

    showLoggedOutBanner() {
      // MustLoginSection should already have a modal
    }
  }
}
</script>

<style>
.centered {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
}

.profile-image-style {
  border-radius: 20px;
}
</style>
